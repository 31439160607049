import revive_payload_client_4sVQNw7RlN from "/var/www/test/pony-client-shopwizzu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/test/pony-client-shopwizzu/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/test/pony-client-shopwizzu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/test/pony-client-shopwizzu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/var/www/test/pony-client-shopwizzu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/var/www/test/pony-client-shopwizzu/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/test/pony-client-shopwizzu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_3AyO8nEfhE from "/var/www/test/pony-client-shopwizzu/plugins/sentry.ts";
import axios_QMFgzss1s4 from "/var/www/test/pony-client-shopwizzu/plugins/axios.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  chunk_reload_client_UciE0i6zes,
  sentry_3AyO8nEfhE,
  axios_QMFgzss1s4
]