import { default as indexrCs3oG8jrrMeta } from "/var/www/test/pony-client-shopwizzu/pages/401/index.vue?macro=true";
import { default as indexCjsG6IhP7JMeta } from "/var/www/test/pony-client-shopwizzu/pages/contacts/index.vue?macro=true";
import { default as index4GfOSwy7ljMeta } from "/var/www/test/pony-client-shopwizzu/pages/delivery-terms/index.vue?macro=true";
import { default as indexKbdRL9AAHTMeta } from "/var/www/test/pony-client-shopwizzu/pages/index.vue?macro=true";
import { default as index7axkD5ZksxMeta } from "/var/www/test/pony-client-shopwizzu/pages/limitations/index.vue?macro=true";
import { default as indexggARbxz8zWMeta } from "/var/www/test/pony-client-shopwizzu/pages/limits/index.vue?macro=true";
import { default as index6XBlC28jhCMeta } from "/var/www/test/pony-client-shopwizzu/pages/payment-methods/index.vue?macro=true";
import { default as failRIPblOAhBXMeta } from "/var/www/test/pony-client-shopwizzu/pages/payment/callback/fail.vue?macro=true";
import { default as successB6PToWEDgfMeta } from "/var/www/test/pony-client-shopwizzu/pages/payment/callback/success.vue?macro=true";
import { default as failUe0TMHDb63Meta } from "/var/www/test/pony-client-shopwizzu/pages/payment/fail.vue?macro=true";
import { default as indexmtRPODhYBBMeta } from "/var/www/test/pony-client-shopwizzu/pages/payment/index.vue?macro=true";
import { default as successw32jVliR3zMeta } from "/var/www/test/pony-client-shopwizzu/pages/payment/success.vue?macro=true";
import { default as indexn8NORLYgSWMeta } from "/var/www/test/pony-client-shopwizzu/pages/shipping-calculator/Backup/index.vue?macro=true";
import { default as indexKfDUpLajn3Meta } from "/var/www/test/pony-client-shopwizzu/pages/shipping-calculator/index.vue?macro=true";
import { default as _91id_93XYhIbntwcEMeta } from "/var/www/test/pony-client-shopwizzu/pages/shops/[id].vue?macro=true";
import { default as indexWeWIIze7f9Meta } from "/var/www/test/pony-client-shopwizzu/pages/shops/index.vue?macro=true";
import { default as indexzG0z0rAYbzMeta } from "/var/www/test/pony-client-shopwizzu/pages/techworks/index.vue?macro=true";
import { default as indexLgcDEWNcjpMeta } from "/var/www/test/pony-client-shopwizzu/pages/terms/index.vue?macro=true";
import { default as privacyIzYDJQzq5wMeta } from "/var/www/test/pony-client-shopwizzu/pages/terms/privacy.vue?macro=true";
export default [
  {
    name: indexrCs3oG8jrrMeta?.name ?? "401",
    path: indexrCs3oG8jrrMeta?.path ?? "/401",
    meta: indexrCs3oG8jrrMeta || {},
    alias: indexrCs3oG8jrrMeta?.alias || [],
    redirect: indexrCs3oG8jrrMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/401/index.vue").then(m => m.default || m)
  },
  {
    name: indexCjsG6IhP7JMeta?.name ?? "contacts",
    path: indexCjsG6IhP7JMeta?.path ?? "/contacts",
    meta: indexCjsG6IhP7JMeta || {},
    alias: indexCjsG6IhP7JMeta?.alias || [],
    redirect: indexCjsG6IhP7JMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index4GfOSwy7ljMeta?.name ?? "delivery-terms",
    path: index4GfOSwy7ljMeta?.path ?? "/delivery-terms",
    meta: index4GfOSwy7ljMeta || {},
    alias: index4GfOSwy7ljMeta?.alias || [],
    redirect: index4GfOSwy7ljMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/delivery-terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexKbdRL9AAHTMeta?.name ?? "index",
    path: indexKbdRL9AAHTMeta?.path ?? "/",
    meta: indexKbdRL9AAHTMeta || {},
    alias: indexKbdRL9AAHTMeta?.alias || [],
    redirect: indexKbdRL9AAHTMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7axkD5ZksxMeta?.name ?? "limitations",
    path: index7axkD5ZksxMeta?.path ?? "/limitations",
    meta: index7axkD5ZksxMeta || {},
    alias: index7axkD5ZksxMeta?.alias || [],
    redirect: index7axkD5ZksxMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/limitations/index.vue").then(m => m.default || m)
  },
  {
    name: indexggARbxz8zWMeta?.name ?? "limits",
    path: indexggARbxz8zWMeta?.path ?? "/limits",
    meta: indexggARbxz8zWMeta || {},
    alias: indexggARbxz8zWMeta?.alias || [],
    redirect: indexggARbxz8zWMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/limits/index.vue").then(m => m.default || m)
  },
  {
    name: index6XBlC28jhCMeta?.name ?? "payment-methods",
    path: index6XBlC28jhCMeta?.path ?? "/payment-methods",
    meta: index6XBlC28jhCMeta || {},
    alias: index6XBlC28jhCMeta?.alias || [],
    redirect: index6XBlC28jhCMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment-methods/index.vue").then(m => m.default || m)
  },
  {
    name: failRIPblOAhBXMeta?.name ?? "payment-callback-fail",
    path: failRIPblOAhBXMeta?.path ?? "/payment/callback/fail",
    meta: failRIPblOAhBXMeta || {},
    alias: failRIPblOAhBXMeta?.alias || [],
    redirect: failRIPblOAhBXMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment/callback/fail.vue").then(m => m.default || m)
  },
  {
    name: successB6PToWEDgfMeta?.name ?? "payment-callback-success",
    path: successB6PToWEDgfMeta?.path ?? "/payment/callback/success",
    meta: successB6PToWEDgfMeta || {},
    alias: successB6PToWEDgfMeta?.alias || [],
    redirect: successB6PToWEDgfMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment/callback/success.vue").then(m => m.default || m)
  },
  {
    name: failUe0TMHDb63Meta?.name ?? "payment-fail",
    path: failUe0TMHDb63Meta?.path ?? "/payment/fail",
    meta: failUe0TMHDb63Meta || {},
    alias: failUe0TMHDb63Meta?.alias || [],
    redirect: failUe0TMHDb63Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexmtRPODhYBBMeta?.name ?? "payment",
    path: indexmtRPODhYBBMeta?.path ?? "/payment",
    meta: indexmtRPODhYBBMeta || {},
    alias: indexmtRPODhYBBMeta?.alias || [],
    redirect: indexmtRPODhYBBMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: successw32jVliR3zMeta?.name ?? "payment-success",
    path: successw32jVliR3zMeta?.path ?? "/payment/success",
    meta: successw32jVliR3zMeta || {},
    alias: successw32jVliR3zMeta?.alias || [],
    redirect: successw32jVliR3zMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: indexn8NORLYgSWMeta?.name ?? "shipping-calculator-Backup",
    path: indexn8NORLYgSWMeta?.path ?? "/shipping-calculator/Backup",
    meta: indexn8NORLYgSWMeta || {},
    alias: indexn8NORLYgSWMeta?.alias || [],
    redirect: indexn8NORLYgSWMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/shipping-calculator/Backup/index.vue").then(m => m.default || m)
  },
  {
    name: indexKfDUpLajn3Meta?.name ?? "shipping-calculator",
    path: indexKfDUpLajn3Meta?.path ?? "/shipping-calculator",
    meta: indexKfDUpLajn3Meta || {},
    alias: indexKfDUpLajn3Meta?.alias || [],
    redirect: indexKfDUpLajn3Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/shipping-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XYhIbntwcEMeta?.name ?? "shops-id",
    path: _91id_93XYhIbntwcEMeta?.path ?? "/shops/:id()",
    meta: _91id_93XYhIbntwcEMeta || {},
    alias: _91id_93XYhIbntwcEMeta?.alias || [],
    redirect: _91id_93XYhIbntwcEMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/shops/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWeWIIze7f9Meta?.name ?? "shops",
    path: indexWeWIIze7f9Meta?.path ?? "/shops",
    meta: indexWeWIIze7f9Meta || {},
    alias: indexWeWIIze7f9Meta?.alias || [],
    redirect: indexWeWIIze7f9Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: indexzG0z0rAYbzMeta?.name ?? "techworks",
    path: indexzG0z0rAYbzMeta?.path ?? "/techworks",
    meta: indexzG0z0rAYbzMeta || {},
    alias: indexzG0z0rAYbzMeta?.alias || [],
    redirect: indexzG0z0rAYbzMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/techworks/index.vue").then(m => m.default || m)
  },
  {
    name: indexLgcDEWNcjpMeta?.name ?? "terms",
    path: indexLgcDEWNcjpMeta?.path ?? "/terms",
    meta: indexLgcDEWNcjpMeta || {},
    alias: indexLgcDEWNcjpMeta?.alias || [],
    redirect: indexLgcDEWNcjpMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacyIzYDJQzq5wMeta?.name ?? "terms-privacy",
    path: privacyIzYDJQzq5wMeta?.path ?? "/terms/privacy",
    meta: privacyIzYDJQzq5wMeta || {},
    alias: privacyIzYDJQzq5wMeta?.alias || [],
    redirect: privacyIzYDJQzq5wMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony-client-shopwizzu/pages/terms/privacy.vue").then(m => m.default || m)
  }
]